<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
<b-avatar
        size="70px"
        :src="require('@/assets/images/logo/logo.png')"
      />
          <h2 class="brand-text text-primary ml-1">
            كلية النسور الجامعة
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          منصة المنتسبين
        </b-card-title>
        <b-card-text class="mb-2">
          اهلا و سهلا بكم في منصة النسور الرقمية.
        </b-card-text>
        <p class="text-center mt-2">
          <b-link v-if="status" :to="{name:'auth'}">
            <feather-icon icon="ChevronLeftIcon" /> انتقل للصفحة الرئيسية
          </b-link>
          <b-link @click="goBack" v-if="!status" >
          <p class="text-center mt-2">
          مع الاسف , يوجد خطا في الايميل او الباسورد او قد يكون حسابك متوقف يرجى مراجعة شعبة الاعلام و تكنولوجيا المعلومات 
          </p>
            <feather-icon icon="ChevronLeftIcon" /> العودة لتسجيل الدخول
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import store from "@/store/index"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle,BAvatar, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    store,
    VuexyLogo,
    BCard,
    BButton,BAvatar,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      full_name:'',
      status:false
    }
  },
  mounted(){
    this.storeInfo()
  },
  methods: {
    storeInfo() {
      this.axios
        .get("users/me")
        .then((res) => {
          store.commit("storeInfo", res);
          this.status = res.data.status
          if(res.data.status==true){
            localStorage.setItem('NisourStatus',true)
          }else{
            localStorage.removeItem('NisourStatus')
          }
        }).catch((e)=>{
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "Action failed",
              "loged in with error)"
            );
          } else {
            this.errorToast();
          }
        })
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    goBack(){
      localStorage.removeItem("NisourUserId")
      localStorage.removeItem("NisourTeacherId")
      localStorage.removeItem("NisourRole")
      localStorage.removeItem("NisourToken")
      localStorage.removeItem("NisourEmail")
      this.$router.push("/login");
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
